import React, { useContext, useEffect, useRef, useState } from "react"
import img_pack from "../../img/img";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { Context } from "../../App";
import CustomDpd from "./Custom_dp_link";
import globalFunction, { CartOrderPageRoot, CartPageRoot, Email_verify_Root, ExchangeAndRefundRoot, FaqPageRoot, Login_Root, Password_new_Root, Password_recovery_Root, PaymentAndDeliveryRoot, Registration_Root, breadcrumbMap, egoda_url, splitPathname } from "../../utils/consts";
import BreadCrumb from "./BreadCrumb";
import ProductCounter from "./ProductCounter";

const Header = () => {

    const {windowWidth,ShowCart,UpdateCart,updateQuantity,DeleteFromCart, cart, productsAll, setProductsAll} = useContext(Context)
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [cartIsOpen, setCartIsOpen] = useState(false);

    const navigate = useNavigate()
    const location = useLocation()

    function deleteProduct(id) {
        const data = new FormData();
        data.append("product_id", id || '');
        DeleteFromCart(data)
    }

    useEffect(() => {
        if (location.pathname.toLowerCase() === CartPageRoot || location.pathname.toLowerCase() === CartOrderPageRoot) {
            setCartIsOpen(false);
        }
    }, [location.pathname]); 

    return (
    <>
        {windowWidth <= 1000 &&
        <div className={`overlay ${menuIsOpen ? 'open' : ''}`} onClick={() => setMenuIsOpen(false)}>
            <div className={`mobile_menu ${menuIsOpen ? 'open' : ''}`} onClick={e => e.stopPropagation()}>
                <div className="mobile_menu_content">
                    <div>
                        <div className="col">
                            <div className="mobile_menu_row_js">
                                <img src={img_pack.assauto_logo} alt="logo" />
                                <button className="close-btn" onClick={() => setMenuIsOpen(false)}><img src={img_pack.close_icon} alt="close_icon" /></button>
                            </div>
                            <Link to={'#!'} className="txtc h-14-400-i">+ 7 (999) 999-99-99</Link>
                        </div>
                        <div className="col" style={{padding: '10px 16px'}}>
                            <CustomDpd title={'Магазин'} className={'h-14-500-i'}>
                                <Link to={'#!'} className="h-14-400-i">1</Link>
                                <Link to={'#!'} className="h-14-400-i">2</Link>
                                <Link to={'#!'} className="h-14-400-i">3</Link>
                            </CustomDpd>
                            <CustomDpd title={'Категории товаров'} className={'h-14-500-i'}>
                                <Link to={'#!'} className="h-14-400-i">1</Link>
                                <Link to={'#!'} className="h-14-400-i">2</Link>
                                <Link to={'#!'} className="h-14-400-i">3</Link>
                            </CustomDpd>
                            <CustomDpd title={'Бренды'} className={'h-14-500-i'}>
                                <Link to={'#!'} className="h-14-400-i">1</Link>
                                <Link to={'#!'} className="h-14-400-i">2</Link>
                                <Link to={'#!'} className="h-14-400-i">3</Link>
                            </CustomDpd>
                            <Link className="menu-link h-14-500-i" to={'#!'}>Акции</Link>
                            <Link className="menu-link h-14-500-i" to={'#!'}>Наборы</Link>
                            <Link className="menu-link h-14-500-i" to={'#!'}>Скидки</Link>
                        </div>
                    </div>
                    <div></div>

                </div>
            </div>
        </div>
        } 
        {(location.pathname.toLowerCase() !== CartPageRoot && location.pathname.toLowerCase() !== CartOrderPageRoot) &&
        <div className={`overlay ${cartIsOpen ? 'open' : ''}`} onClick={() => setCartIsOpen(false)}>
            <div className={`cart_menu ${cartIsOpen ? 'open' : ''}`} onClick={e => e.stopPropagation()}>
                <div className="mobile_menu_content">
                    <div>
                        <div className="mobile_menu_row_js">
                            <div className="row-10-a">
                                <img src={img_pack.cart_icon} alt="cart_icon" />
                                <span className="h-16-600-i">Корзина</span>
                            </div>
                            <button className="close-btn" onClick={() => setCartIsOpen(false)}><img src={img_pack.close_icon} alt="close_icon" /></button>
                        </div>
                        <div className="col">
                            {Object.keys(productsAll)?.length > 0 && Object.values(productsAll)?.map((el) => (
                                <div className="cart_menu_product" key={el.product_id}>
                                    <div>
                                    {el.image_url ?
                                        <img style={{height: '50px', minWidth: '50px', borderRadius: '10px'}} src={`${egoda_url}${el.image_url}`} alt="img" />
                                        : <img style={{height: '50px', width: '50px'}} src={`${img_pack.product_example}`} alt="img" />
                                    }
                                    </div>
                                    <div className="col-10">
                                        <div className="h-14-400-i">{el.product_name}</div>
                                        <div style={{width: 'max-content'}}>
                                            <ProductCounter onChange500={async (e) => await updateQuantity(e,el.product_id) } initialCount={el.quantity} />
                                        </div>
                                        <div className="h-16-400-i"><span className="gr5">{el.quantity} шт.</span> x {globalFunction.withSpaces(globalFunction.split(el.price)[0])} ₽ = {globalFunction.withSpaces(globalFunction.split(el.price * el.quantity)[0])} ₽</div>
                                    </div>
                                    <div>
                                        <button className="cart_menu_del_btn" onClick={() => deleteProduct(el.product_id)}>
                                            <img src={img_pack.close_icon} alt="close_icon" /> 
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className="row-20-js" style={{padding: '20px 0'}}>
                            <div className="h-16-600-i">Сумма:</div>
                            <div className="h-16-600-i">{globalFunction.withSpaces(globalFunction.split(+cart?.total_price)[0])} ₽</div>
                        </div>
                        <div className="col-10">
                            <button className="base-btn-em h-14-400-i" onClick={() => {navigate(`${CartPageRoot}`); setCartIsOpen(false)} }>Просмотр корзины</button>
                            <button className="base-btn white h-14-400-i" disabled={Object.keys(productsAll)?.length > 0 ? false : true} onClick={() => {navigate(`${CartOrderPageRoot}`);setCartIsOpen(false)} }>Оформление заказа</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>}

        {windowWidth > 1000 ?
        <header>
            <div className="container">
                <div className="header-main-row">
                    <Link to={'/'} className="header-logo">
                        <img src={`${img_pack.assauto_logo}`} alt="logo" />
                    </Link>
                    <div className="nav-menu h-14-400-j">
                        <Link to={PaymentAndDeliveryRoot}>
                            <span>Доставка</span>
                        </Link>
                        <Link to={PaymentAndDeliveryRoot}>
                            <span>Оплата</span>
                        </Link>
                        <Link to={ExchangeAndRefundRoot}>
                            <span>Возврат</span>
                        </Link>
                        <Link to='#!'>
                            <span>Контакты</span>
                        </Link>
                    </div>
                    <div className="row-15-a">
                        <div className="col">
                            <Link className="brand h-20-600-i" to={'tel:+73517764991'}>+7 (351) 776-49-91</Link>
                            <Link className="brand h-12-600-i" to={'mailto:tkass74@internet.ru'}>tkass74@internet.ru</Link>
                            <span className="h-12-400-i gr5">пн-пт: 9:00-18:00, сб-вс: выходной</span>
                        </div>
                        <span style={{borderRight: '1px solid #D0D5DD', width: '1px', height: '29px'}}/>
                        <Link to={'/contact_po'} state={{prevLocation: location}} className="call_btn h-14-400-j">Заказать звонок</Link>
                    </div>
                </div>
                <div className="header-foot">
                    <button className="catalog_btn">
                        <span />
                        <span className="h-14-400-j white">Каталог шин и дисков</span>
                    </button>
                    <form className="header-search-form">
                        <input className="base-input" type="text" placeholder="Найти"/>
                        <button type="submit"><img src={img_pack.search_icon} alt="ic" /></button>
                    </form>
                    <button className="header-cart-row" onClick={() => setCartIsOpen(!cartIsOpen)}>
                        <img src={img_pack.cart_icon} alt="cart_icon" />
                        <div className="h-14-400-i gr7">{cart?.total_price ? globalFunction.withSpaces(globalFunction.split(cart?.total_price)[0]) : 0} ₽</div>
                    </button>
                </div>
            </div>
        </header>
        :
        <header>
            <div className="container">
                <div className="header-main-row">
                    <button onClick={() => setMenuIsOpen(!menuIsOpen)}>
                        <img src={img_pack.menu_icon} alt="menu_icon" />
                    </button>
                    <Link to={'#'} className="header-logo">
                        <img src={`${img_pack.assauto_logo}`} alt="logo" />
                    </Link>
                    <div className="row-15-a">
                        <button><img src={img_pack.search_icon} alt="cart_icon" /></button>
                        <button><img src={img_pack.cart_icon} alt="cart_icon" onClick={() => setCartIsOpen(true)}/></button>
                    </div>
                </div>
            </div>
        </header>
        }
    <BreadCrumb></BreadCrumb>
    </>
    )
}

export default Header;