import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import img_pack from "../../img/img";

const CustomChoiceDpd = ({el, q, children, selectedCat, setSelectedCat}) => {

    const [dpOpen, setDpOpen] = useState(false);
    const curContent = useRef()

    function click() {
        setDpOpen(!dpOpen)
        const contentDpd = curContent.current
        const curContentHeight = contentDpd?.scrollHeight;

        if(contentDpd.classList.contains('open')) {
            contentDpd.classList.remove('open')
            contentDpd.style.maxHeight = '0';
        } else {
            contentDpd.classList.add('open')
            contentDpd.style.maxHeight = curContentHeight + 'px';
        }
    }

    const hasSlugInTree = (node, slugToFind) => {
        let found = false;
        function search(currentNode) {
            if (currentNode.slug === slugToFind) {
                found = true;
                return; // Если найдено, можем сразу выйти
            }
            if (currentNode.children && currentNode.children.length > 0) {
                for (const child of currentNode.children) {
                    search(child);
                    if (found) return; // Прекращаем поиск, если уже нашли
                }
            }
        }
        search(node);
        return found;
    };

    useEffect(() => {
        if(dpOpen) {
            if(selectedCat === el.slug) {
                setSelectedCat('')
            } else {
                setSelectedCat(el.slug)
            }
        } else {
            if(selectedCat !== '') {
                if(selectedCat === el.slug || hasSlugInTree(el, selectedCat)) {
                    setSelectedCat('')
                }
            }
        }
    },[dpOpen])

    useEffect(() => {
        if(dpOpen && selectedCat !== '') {
            if(selectedCat !== el.slug) {
                if(!hasSlugInTree(el, selectedCat)) {
                    click()
                }
            }
        }
        if(dpOpen && selectedCat === '') {
            click()
        }
    },[selectedCat])

    return (
        <div className={`choice-dpd ${dpOpen ? 'active' : ''}`}>
            <button onClick={click} className={`product_choicecat_btn h-14-600-i gr6 ${selectedCat === el.slug ? 'active' : ''}`}>
                <span>{el.name}</span>
                <div>
                    <span className="ba-total">{q}</span>
                    <img src={img_pack.dpd_icon} alt="dpd_icon" style={dpOpen ? {transform: 'rotate(180deg)'} : {}} />
                </div>
            </button>
            <div className={`dpd-content ${dpOpen ? 'open' : ''}`} ref={curContent}>
                {children}
            </div>
        </div>
    )
}

export default CustomChoiceDpd;