import React, { useContext, useEffect, useRef, useState } from 'react';

const ProductCounter = ({initialCount, onChange, onChange500}) => {

  const [count, setCount] = useState(initialCount);
  const prevCountRef = useRef(count);
  const timerRef = useRef(null);

  useEffect(() => {
    if(count!== initialCount) {
      setCount(initialCount)
    }
  },[initialCount])

  useEffect(() => {
    if (prevCountRef.current !== count) {
      if(onChange500) {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        // Установка нового таймера
        timerRef.current = setTimeout(() => {
          console.log('yeap')
          onChange500(count);
        }, 500);
      }
      if(onChange) {
        onChange(count);
      }
      prevCountRef.current = count;
    }
  }, [count])

  return (
    <div className="product-counter h-16-400-i">
        <button onClick={() => setCount(Math.max(count - 1, 1))}>-</button>
        <input type="number" min={1} max={999} value={count || 1} onChange={(e) => {const value = +e.target.value; (value>= 1 && value < 1000) && setCount(value)} }/>
        <button onClick={() => setCount(Math.min(count + 1, 999))}>+</button>
    </div>
  );
};

export default ProductCounter;

