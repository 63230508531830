import React, { useContext, useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import img_pack from "../img/img";
import GetMediafiles from "../API/GetMediafiles";
import { useFetching } from "../hooks/UseFetching";
import { format } from "date-fns";
import { AdminMediafilesRoot, egoda_url } from "../utils/consts";
import PlayerComponent from "./components/PlayerComponents";

const AdminMediafilesRedPage = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const [copyClick, SetCopyClick] = useState(false);
    const [fileData, setFileData] = useState([]);
    const {id} = useParams()
    const [itemPodata, setItemPodata] = useState({title: 'Файл будет удален', desc: 'Вы уверены, что хотите удалить файл безвозвратно?'});
    const cur_form = useRef(null)

    let confirmPo = location.state?.confirmPo

    const [GetFile] = useFetching( async (pk) => {
        const res = await GetMediafiles.GetFile(pk)
        console.log('GetFile complete:' , res.data)
        setFileData(res.data.data)
        cur_form.current.elements.alt.value = res.data.data?.alt
        cur_form.current.elements.title.value = res.data.data?.title
        cur_form.current.elements.sign.value = res.data.data?.label
        cur_form.current.elements.desc.value = res.data.data?.desc
    })
    const [DeleteFile] = useFetching( async (pk) => {
        const res = await GetMediafiles.DeleteFile(pk)
        console.log('DeleteFile complete:' , res.data)
        navigate(`${AdminMediafilesRoot}`)
        return res;
    })
    const [FileEdit] = useFetching( async (pk, data) => {
        const res = await GetMediafiles.FileEdit(pk, data)
        console.log('FileEdit complete:' , res.data)
        navigate(`${AdminMediafilesRoot}`)
        return res;
    })
    
    useEffect(() => {
        GetFile(id)
    },[])

    useEffect(() => {
        if (location.state?.confirmPo) {
            // Обнуление состояния confirm в истории
            confirmPo = undefined
            window.history.replaceState({}, '')
            // Логика на confirmPo
            console.log('Удаление')
            DeleteFile(id)
        }
    }, [location.state])

    function submit(e) {
        e.preventDefault()
        const formData = new FormData();
        formData.append("alt", e.target.elements.alt.value);
        formData.append("title", e.target.elements.title.value);
        formData.append("label", e.target.elements.sign.value);
        formData.append("desc", e.target.elements.desc.value);
        FileEdit(id, formData)
    }

    return (
        <div className="admin_content_base">
            <div className="admin_content_header">
                <div className="h-18-500-i gr8">Информация о файле</div>
                <div className="row-10-a">
                    <Link to={'/item_po'} state={{prevLocation: location, itemPodata: itemPodata}} className="admin_delete_btn">
                        <img src={img_pack.trash_icon} alt="trash_icon" />
                        <span className="h-14-500-i white">Удалить</span>
                    </Link>
                    <button className="admin_save_btn" form="mediafiles-red-form" type="submit">
                        <img src={img_pack.save_icon} alt="save_icon" />
                        <span className="h-14-500-i white">Сохранить</span>
                    </button>
                </div>
            </div>

            <div className="mediafiles-content-red">
                <div>
                    {fileData.category === "VIDEO" ? 
                        fileData.file && <PlayerComponent videoUrl={`${egoda_url}${fileData.file}`}></PlayerComponent>
                        :
                        fileData.file && <img src={`${egoda_url}${fileData.file}`} alt="img-ex" />
                    }
                </div>
                <div>
                    <div>
                        <div><span className="h-12-500-i">Дата загрузки:</span> <span className="h-12-400-i">{fileData.created && format(new Date(fileData.created), 'dd.MM.yyyy')}</span></div>
                        <div><span className="h-12-500-i">Кто загрузил:</span> <span className="h-12-400-i">{fileData?.creator}</span></div>
                        <div><span className="h-12-500-i">Имя файла:</span> <span className="h-12-400-i">{fileData?.file_name}</span></div>
                        <div><span className="h-12-500-i">Категория файла:</span> <span className="h-12-400-i">{fileData?.category}</span></div>
                        <div><span className="h-12-500-i">Тип файла:</span> <span className="h-12-400-i">{fileData?.file_extension}</span></div>
                        <div><span className="h-12-500-i">Размер файла:</span> <span className="h-12-400-i">{fileData?.size} МБ</span></div>
                        <div><span className="h-12-500-i">Разрешение:</span> <span className="h-12-400-i">{fileData?.width} x {fileData?.height} px</span></div>
                    </div>
                    <span style={{borderBottom: '1px solid #D0D5DD'}}/>
                    <form id="mediafiles-red-form" onSubmit={submit} className="mediafiles-red-form" ref={cur_form}>
                        <div className="col-5">
                            <span className="h-14-500-i gr7">Атрибут alt</span>
                            <input name="alt" className="h-16-400-i gr5 base-input" placeholder="Напишите атрибут alt..." type="text" />
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i gr7">Заголовок</span>
                            <input name="title" className="h-16-400-i gr5 base-input" placeholder="Тестовый файл" type="text" />
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i gr7">Подпись</span>
                            <textarea name="sign" id="sign" className="base-input h-16-400-i gr5" placeholder="Напишите подпись..." style={{height: '120px'}} />
                        </div>
                        <div className="col-5">
                            <span className="h-14-500-i gr7">Описание</span>
                            <textarea name="desc" id="desc" className="base-input h-16-400-i gr5" placeholder="Напишите описание..." style={{height: '120px'}} />
                        </div>
                    </form>
                    <div className="col-5">
                        <span className="h-14-500-i gr7">Ссылка на файл</span>
                        <input name="link" className="h-16-400-i gr5 base-input" type="text" defaultValue={fileData?.file} readOnly/>
                    </div>
                    <div className="row-10-a">
                        <button className="copy_clipboard_btn h-14-600-i white" onClick={() => {navigator.clipboard.writeText(fileData?.file); SetCopyClick(true); }}>Скопировать URL в буфер</button>
                        {copyClick &&
                            <span className="h-12-400-i sus7">Скопировано в буфер!</span>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AdminMediafilesRedPage;