import React, { useEffect, useState } from "react"
import img_pack from "../img/img";
import { AdminStoreCouponsRoot } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import GetStore from "../API/GetStore";
import CouponsRedTabs from "./components/CouponsRedTabs";
import { format } from "date-fns";
import FeedbackSettingsTabs from "./components/FeedbackSettingsTabs";
import GetFeedback from "../API/GetFeedback";
import { da } from "date-fns/locale";
import MainSettingsTabs from "./components/MainSettingsTabs";
import GetSettings from "../API/GetSettings";

const AdminSettingsPage = () => {

    const [settingsTabs, setSettingsTabs] = useState({
        id: null,
        admin_emails: [],
    })

    // Базовые запросы \ первичный рендер

    const [GetMainSettings] = useFetching( async () => {
        const res = await GetSettings.GetMainSettings()
        console.log('GetMainSettings complete:' , res.data)
        setSettingsTabs(prevState => ({
            ...prevState,
            id: res.data.detail.id || prevState.id,
            admin_emails: res.data.detail.admin_emails.map((el) => el.email) || prevState.admin_emails,
        }));
    })

    useEffect(() => {
        GetMainSettings()
    },[])

    // Отмена базового поведения формы на Enter
    function handleKeyPress(e) {  
        if (e.keyCode === 13) {
          e.preventDefault();
        }
    }
    useEffect(() => {
        const form = document.querySelector(".new_articles_form")
        form.addEventListener("keypress", handleKeyPress);
        return () => {
            if (form) {
                form.removeEventListener("keypress", handleKeyPress);
            }
        };
    },[])

    const [UpdateMainSettings] = useFetching( async (data) => {
        const res = await GetSettings.UpdateMainSettings(data)
        console.log('UpdateMainSettings complete:' , res.data)
        return res;
    })

    function submit(e) {
        e.preventDefault()
        const data = {
            'admin_emails': settingsTabs?.admin_emails || [],
        }
        UpdateMainSettings(data)
    }

    return (
        <form onSubmit={submit} className="new_articles_form">
            <div className="new_articles_body">
                <div className="h-16-500-i" style={{padding: '10px'}}>Настройки</div>
                <MainSettingsTabs settingsTabs={settingsTabs} setSettingsTabs={setSettingsTabs}/>
            </div>
            <div className="new_articles_r_sidebar">
                <button className="admin_save_btn" type="submit">
                    <img src={img_pack.save_icon} alt="save_icon" />
                    <span className="h-14-500-i white">Сохранить</span>
                </button>
            </div>
        </form>
        
    )
}

export default AdminSettingsPage;