import React, { useContext, useEffect, useState } from "react"
import { Splide, SplideTrack, SplideSlide} from '@splidejs/react-splide'
import img_pack from "../img/img";
import { Context } from "../App";
import {products, cats, posts, StorePageRoot} from "../utils/consts";
import ProductExample from "./components/Product_example";
import YandexMap from "./components/YandexMap";
import { useFetching } from "../hooks/UseFetching";
import GetStore from "../API/GetStore";
import { Link, useLocation } from "react-router-dom";
import SearchBlock from "./components/SearchBlock";
import ProductsCatNav from "./components/ProductsCatNav";

const Main = () => {

    const {windowWidth} = useContext(Context)
    const [productsCreatedDesc, setProductsCreatedDesc] = useState([])
    const [productsAll, setProductsAll] = useState([])

    const location = useLocation()

    function submit(e) {
        e.preventDefault()
    }
    
    // Получение всех товаров
    const [UsersProductsAllCreatedDesc] = useFetching( async (productData, page) => {
        const res = await GetStore.UsersProductsAll(productData, page)
        console.log('UsersProductsAllCreatedDesc complete:' , res.data)
        setProductsCreatedDesc(res.data.result)
    })

    useEffect(() => {
        const formdata = new FormData();

        formdata.append("query", ''); //поиск по тексту
        formdata.append("price_from", ''); //цена от
        formdata.append("price_to", ''); //цена до
        formdata.append("category", ''); //категория (если есть потомки, то отображаются товары потомков в т.ч.)
        formdata.append("sort_by", 'created_desc'); //created_desc / created_asc / price_desc / price_asc
        formdata.append("item_quantity", 8); //кол-во итемов для вывода (дефолт 12)

        UsersProductsAllCreatedDesc(formdata, 1)
    },[])

    return (
    <>
        <section className="block-base" style={{background: 'linear-gradient(270deg, #CFCFCF 0%, rgba(207, 207, 207, 0.305882) 49.5%, #CFCFCF 100%)'}}>
            <div className="container">
                <div className="search-block-content">
                    <SearchBlock />
                    <div>
                        <span className="h-36-500-i">Шины и диски для авто в Челябинске</span>
                        <span className="h-16-400-i">В наличии и под заказ</span>
                        <div style={{display: 'flex'}}><Link to={StorePageRoot} className="base-btn white h-14-400-j">Подробнее</Link></div>
                    </div>
                    <div>
                        <img src={img_pack.search_block_back} alt="img" />
                    </div>
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="main-info-row">
                        <div className="main-info-block">
                            <div>
                                <span className="h-18-400-j" style={{lineHeight: '24px'}}>Отзывы наших покупателей</span>
                                <div><button className="h-14-400-j">Смотреть отзывы</button></div>
                            </div>
                            <div><img src={img_pack.main_infoBlock_avito} alt="img" /></div>
                        </div>
                        <div className="main-info-block">
                            <div>
                                <span className="h-18-400-j" style={{lineHeight: '24px'}}>Нужна помощь в подборе?</span>
                                <div><button className="h-14-400-j">Задать вопрос</button></div>
                            </div>
                            <div><img src={img_pack.main_infoBlock_tires} alt="img" /></div>
                        </div>
                        <div className="main-info-block">
                            <div>
                                <span className="h-18-400-j" style={{lineHeight: '24px'}}>Отзывы наших покупателей</span>
                                <div><button className="h-14-400-j">Смотреть отзывы</button></div>
                            </div>
                            <div><img src={img_pack.main_infoBlock_2gis} alt="img" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="products-title">
                        <div className="h-26-400-j gr8">Лучшие предложения шин</div>
                        <ProductsCatNav catNav={['Зимние','Летние']}/>
                    </div>
                    <div className="products-block">
                        {productsCreatedDesc?.map((el) => (
                            <ProductExample key={el.id} res={el}></ProductExample>
                        ))}
                    </div>
                    {windowWidth <= 600 &&
                        <button className="viewAll-btn" style={{maxWidth: '80%', width: '100%', margin: '0 auto'}}>Посмотреть все</button>
                    }
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="products-title">
                        <div className="h-26-400-j gr8">Популярные бренды</div>
                        <ProductsCatNav catNav={['Шины','Диски']}/>
                    </div>
                    <div className="brands-block">
                        <div className="brand-ex">
                            <img src={img_pack.brand_ex} alt="img" />
                        </div>
                        <div className="brand-ex">
                            <img src={img_pack.brand_ex} alt="img" />
                        </div>
                        <div className="brand-ex">
                            <img src={img_pack.brand_ex} alt="img" />
                        </div>
                        <div className="brand-ex">
                            <img src={img_pack.brand_ex} alt="img" />
                        </div>
                        <div className="brand-ex">
                            <img src={img_pack.brand_ex} alt="img" />
                        </div>
                        <div className="brand-ex">
                            <img src={img_pack.brand_ex} alt="img" />
                        </div>
                        <div className="brand-ex">
                            <img src={img_pack.brand_ex} alt="img" />
                        </div>
                        <div className="brand-ex">
                            <img src={img_pack.brand_ex} alt="img" />
                        </div>
                    </div>
                    {windowWidth <= 900 &&
                        <button className="viewAll-btn" style={{maxWidth: '80%', width: '100%', margin: '0 auto'}}>Посмотреть все</button>
                    }
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container"> 
                <div className="block-base-content">
                    <div className="h-26-400-j c-2">Почему лучше покупать у нас?</div>
                    <div className="prv-block-row">
                        <div className="prv-block-ex">
                            <div className="prv-block-img">
                                <img src={img_pack.prv_truck} alt="ic" />
                            </div>
                            <div className="h-18-600-i">Доставка по Челябинску</div>
                            <div className="h-16-400-i">Доставим в удобное время и место.</div>
                        </div>
                        <div className="prv-block-ex">
                            <div className="prv-block-img">
                                <img src={img_pack.prv_map} alt="ic" />
                            </div>
                            <div className="h-18-600-i">Доставка ТК во все регионы РФ</div>
                            <div className="h-16-400-i">Быстрые сроки доставки и удобное получение</div>
                        </div>
                        <div className="prv-block-ex">
                            <div className="prv-block-img">
                                <img src={img_pack.prv_card} alt="ic" />
                            </div>
                            <div className="h-18-600-i">Различные способы оплаты</div>
                            <div className="h-16-400-i">Оплатите удобным способом</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container"> 
                <div className="block-base-content">
                    <div className="contacts-block">
                        <div className="contact-info">
                            <div className="h-26-400-j c-2">Контакты</div>
                            <div className="contacts-row">
                                <div className="contact-ex">
                                    <a href="tel:+73517764991" className="brand h-24-600-i">+7 (351) 776-49-91</a>
                                    <span className="h-16-400-i">пн-пт: 9:00-18:00, сб-вс: выходной</span>
                                    <Link to={'/contact_po'} state={{prevLocation: location}} className="call_btn h-14-400-j">Заказать звонок</Link>
                                </div>
                                <div className="contact-ex">
                                    <a href="mailto:tkass74@internet.ru" className="brand h-24-600-i">tkass74@internet.ru</a>
                                    <span className="h-16-400-i gr5">Напишите нам</span>
                                </div>
                                <div className="row-15">
                                    <Link to={'#!'}><img src={`${img_pack.contact_whatsappIcon}`} alt="ic" style={{minWidth: '32px', maxWidth: '32px'}}/></Link>
                                    <Link to={'#!'}><img src={`${img_pack.contact_telegramIcon}`} alt="ic" style={{minWidth: '32px', maxWidth: '32px'}}/></Link>
                                </div>
                            </div>
                            <div className="contact-ex">
                                <div className="row-15-a">
                                    <img src={`${img_pack.contact_mappinIcon}`} alt="ic" />
                                    <span className="brand h-16-400-i">Офис</span>
                                </div>
                                <span className="h-16-400-i">Челябинск, ​Академика Макеева, 36, офис 25</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

export default Main;