import React, { useEffect, useState } from "react"
import img_pack from "../img/img";
import { AdminStoreCouponsRoot } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import GetStore from "../API/GetStore";
import CouponsRedTabs from "./components/CouponsRedTabs";
import { format } from "date-fns";
import FeedbackSettingsTabs from "./components/FeedbackSettingsTabs";
import GetFeedback from "../API/GetFeedback";
import { da } from "date-fns/locale";

const AdminOrderSettings = () => {

    const [settingsTabs, setSettingsTabs] = useState({
        id: null,
        notification_emails: [],
        notify_admin: false,
    })

    // Базовые запросы \ первичный рендер

    // const [GetFeedbackSettings] = useFetching( async () => {
    //     const res = await GetFeedback.GetFeedbackSettings()
    //     console.log('GetFeedbackSettings complete:' , res.data)
    //     setSettingsTabs(prevState => ({
    //         ...prevState,
    //         id: res.data.detail.id || prevState.id,
    //         notification_emails: res.data.detail.notification_emails.map((el) => el.email) || prevState.notification_emails,
    //         notify_admin: res.data.detail.notify_admin || prevState.notify_admin,
    //     }));
    // })

    // useEffect(() => {
    //     GetFeedbackSettings()
    // },[])

    // Отмена базового поведения формы на Enter
    function handleKeyPress(e) {  
        if (e.keyCode === 13) {
          e.preventDefault();
        }
    }
    useEffect(() => {
        const form = document.querySelector(".new_articles_form")
        form.addEventListener("keypress", handleKeyPress);
        return () => {
            if (form) {
                form.removeEventListener("keypress", handleKeyPress);
            }
        };
    },[])

    // const [UpdateFeedbackSettings] = useFetching( async (data) => {
    //     const res = await GetFeedback.UpdateFeedbackSettings(data)
    //     console.log('UpdateFeedbackSettings complete:' , res.data)
    //     return res;
    // })

    function submit(e) {
        e.preventDefault()
        // const data = {
        //     'notification_emails': settingsTabs?.notification_emails || [],
        //     'notify_admin': settingsTabs?.notify_admin || false
        // }
        // UpdateFeedbackSettings(data)
    }

    return (
        <form onSubmit={submit} className="new_articles_form">
            <div className="new_articles_body">
                <div className="h-16-500-i" style={{padding: '10px'}}>Настройки</div>
                <FeedbackSettingsTabs settingsTabs={settingsTabs} setSettingsTabs={setSettingsTabs}/>
            </div>
            <div className="new_articles_r_sidebar">
                <button className="admin_save_btn" type="submit">
                    <img src={img_pack.save_icon} alt="save_icon" />
                    <span className="h-14-500-i white">Сохранить</span>
                </button>
            </div>
        </form>
        
    )
}

export default AdminOrderSettings;