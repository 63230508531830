import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import img_pack from "../img/img";
import { useFetching } from "../hooks/UseFetching";
import Pagination from "./components/Pagination";
import { AdminContactRedRootBase, feedbackStatusMap } from "../utils/consts";
import GetStore from "../API/GetStore";
import GetFeedback from "../API/GetFeedback";
import { format } from "date-fns";

const AdminContactPage = () => {

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null)
    const [totalPosts, setTotalPosts] = useState(null)
    const [curId, setCurId] = useState('');
    const [itemPodata, setItemPodata] = useState({title: 'Заявка будет удалена', desc: 'Вы уверены, что хотите удалить заявку безвозвратно?'});

    const [requestAll, setRequestAll] = useState([])
    const location = useLocation()
    const navigate = useNavigate()

    function checkClick(e) {
        if (e.target.classList.contains('active')) {
            e.target.classList.remove('active')
        } else {
            e.target.classList.add('active')
        }
    }

    const [GetFeedbacksPage, isLoading] = useFetching( async (page) => {
        const res = await GetFeedback.GetFeedbacksPage(page)
        setRequestAll(res.data.data)
        setTotalPages(res.data?.total_pages)
        setTotalPosts(res.data?.total)
        console.log('GetFeedbacksPage complete:' , res.data)
    })

    useEffect(() => {
        GetFeedbacksPage(currentPage)
    },[currentPage])

    // Удаление бренда
    function deleteRequest(id) {
        setCurId(id)
        navigate('/item_po', { state: { prevLocation: location, itemPodata: itemPodata } })
    }

    const [DeleteFeedbackRequest] = useFetching( async (id) => {
        const res = await GetFeedback.DeleteFeedbackRequest(id)
        console.log('DeleteFeedbackRequest complete:' , res.data)
        GetFeedbacksPage(1)
        return res;
    })

    useEffect(() => {
        if (location.state?.confirmPo) {
            // Обнуление состояния confirm в истории
            location.state.confirmPo = undefined
            window.history.replaceState({}, '')
            // Логика на confirmPo
            DeleteFeedbackRequest(curId)
            setCurId('')
        }
    }, [location.state])

    return (
        <div className="admin_content_base">
            <div className="admin_content_header">
                <div className="row-10-a">
                    <div className="h-18-500-i gr8">Заявки</div>
                    <span className="h-12-500-i ba-or">{totalPosts} шт</span>
                </div>
            </div>

            <table className="articles_admin_table">
                <thead>
                    <tr>
                        <th>
                            <div>
                                <span className="check_box_base" onClick={checkClick}/>
                                Заказ
                            </div>
                        </th>
                        <th>
                            <div>
                                Статус
                                <img src={img_pack.dpd_icon} alt="dpd_icon" />
                            </div>
                        </th>
                        <th>
                            <div>
                                Дата
                                <img src={img_pack.dpd_icon} alt="dpd_icon" />
                            </div>
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {requestAll?.map((el) => (
                        <tr key={el.id}>
                            <td className="tcats-name">
                                <div>
                                    <span className="check_box_base" onClick={checkClick}/>
                                    <span className="h-14-500-i gr7">{el.name},{el.email},{el.tel}</span>
                                </div>
                            </td>
                            <td className="t-status">
                                <div className={feedbackStatusMap[el.status]?.className || 'ba'}>
                                    <div />
                                    <span className="h-12-500-i">{feedbackStatusMap[el.status]?.text || "Неизвестный статус"}</span>
                                </div>
                            </td>
                            <td className="t-date">
                                <span className="h-12-500-i gr7">{format(new Date(el.updated), 'dd.MM.yyyy') }</span>
                            </td>
                            <td className="t-icons">
                                <div>
                                    <button onClick={() => deleteRequest(el.id)}><img src={img_pack.articles_trash_icon} alt="trash_icon" /></button>
                                    <Link to={`${AdminContactRedRootBase}${el.id}`}><img src={img_pack.articles_edit_icon} alt="edit_icon" /></Link>
                                </div>
                            </td>

                        </tr>
                    ))
                    }
                </tbody>
            </table>
            {totalPages > 1 &&
            <div className="t_foot">
                <button onClick={() => setCurrentPage( ((currentPage - 1) >= 1) ? (currentPage - 1) : (currentPage) )}>
                    <span className="prev_arrow_svg"/>
                    <span className="h-14-600-i gr7">Предыдущее</span>
                </button>
                <Pagination totalPages={totalPages} pagesToShow={1} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                <button onClick={() => setCurrentPage( ((currentPage + 1) <= totalPages) ? (currentPage + 1) : (currentPage) )}>
                    <span className="h-14-600-i gr7">Следующие</span>
                    <span className="next_arrow_svg"/>
                </button>
            </div>
            }
        </div>
    )
}

export default AdminContactPage;