import React, { useContext, useEffect, useState } from "react"
import img_pack from "../../img/img";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { Context } from "../../App";
import { CartOrderPageRoot, CartPageRoot, Email_verify_Root, FaqPageRoot, Login_Root, OrderPageRoot, Password_new_Root, Password_recovery_Root, Registration_Root, StorePageRoot } from "../../utils/consts";

const BreadCrumb = () => {

    const breadcrumbMap = {
        [Login_Root.toLowerCase()]: 'Авторизация',
        [Registration_Root.toLowerCase()]: 'Регистрация',
        [Password_recovery_Root.toLowerCase()]: 'Восстановление пароля',
        [Password_new_Root.toLowerCase()]: 'Создание нового пароля',
        [Email_verify_Root.toLowerCase()]: 'Подтверждение почты',
        [FaqPageRoot.toLowerCase()]: 'Вопросы и ответы',
        [StorePageRoot.toLowerCase()]: 'Магазин',
        [CartPageRoot.toLowerCase()]: 'Корзина',
        [CartOrderPageRoot.toLowerCase()]: 'Оформление заказа',
        [OrderPageRoot.toLowerCase()]: 'Заказ',
    };

    function splitPathname(pathname) {
        // Удаляем первый слэш, если он есть
        if (pathname.startsWith('/')) {
            pathname = pathname.slice(1);
        }
        // Разделяем строку по слэшам
        let parts = pathname.split('/');
        // Возвращаем массив, содержащий части пути
        return parts;
    }

    const [navArr, setNavArr] = useState([]);
    const {slugParam, catParam} = useParams() //productsPage

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if(location) {
           setNavArr(splitPathname(location.pathname))
        }
    },[location])

    return (
    <>
    {(location.pathname !== '/' && location.pathname !== '/main') &&
    <div className="header-page-nav">
        <div className="container">
            <div className="header-page-nav-content">
                <div className="col">
                    <div className="row-20-a">
                        <button className="gr8 h-12-400-i" onClick={() => navigate(-1)}><img style={{minWidth: 'max-content'}} src={img_pack.back_icon} alt="back_icon" /></button>
                        <div className="h-36-500-c gr8">
                            {slugParam ? slugParam : breadcrumbMap[location.pathname.toLowerCase()]}
                        </div>
                    </div>
                    <div className="row-10-a h-12-400-i">
                        <Link className="gr8 h-12-400-i" to='/'>Главная</Link>
                        {navArr && navArr.map((el) => (
                            <React.Fragment key={el}>
                                <span className="h-12-400-i gr8">/</span>
                                <Link className={`${navArr[navArr.length-1] === el ? 'h-12-700-i' : 'h-12-400-i'}`} to={`/${el}`}>
                                    { el.toLowerCase() === slugParam ? slugParam : el.toLowerCase() === catParam ? catParam : breadcrumbMap[`/${el.toLowerCase()}`] }
                                </Link>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    </>
    )
}

export default BreadCrumb;