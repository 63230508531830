import { useState } from "react";
import { useNavigate } from "react-router";
import { Bounce, toast } from "react-toastify";

export const useFetching = (callback) => {
    const[isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    // const navigate = useNavigate()

    const fetching = async (...params) => {
        try {
            setIsLoading(true)
            const res = await callback(...params)
            if(res?.data?.mes) {
                toast.success(`${res.data.mes}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
            
        } catch (e) {
            setError(e)
            console.log(e)

            const { slug, mes, errors } = e.response?.data || {};
            const errorMessage = slug || mes || errors;
            if (errorMessage) {
                toast.error(`${errorMessage}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
                
            if (e.response?.status === 401) {
                console.log(e)
                // navigate(`${Auth_Root}`)
            }
        } finally {
            setIsLoading(false)
        }
    }
    
    return [fetching, isLoading, error]
}