import React, { useContext, useEffect, useState } from "react"
import ProductCatChoice from "./ProductCatChoice";
import PriceRangeSlider from "./PriceRangeSlider";
import { useFetching } from "../../hooks/UseFetching";
import GetStore from "../../API/GetStore";

const FilterSearchBlock = ({rangePrice, setRangePrice, sortingProducts, query, setQuery, selectedCat, setSelectedCat, selectedBrand, setSelectedBrand}) => {

    const [allBrands, setAllBrands] = useState([]);
    const [allCats, setAllCats] = useState([]);
    
    // Получение всех брендов
    const [GetStoreBrandSidebar] = useFetching( async () => {
        const res = await GetStore.GetStoreBrandSidebar()
        console.log('GetStoreBrandSidebar complete:' , res.data)
        setAllBrands(res.data.data)
    })

    // Получение всех категорий
    const [GetStoreCatsSidebar] = useFetching( async () => {
        const res = await GetStore.GetStoreCatsSidebar()
        console.log('GetStoreCatsSidebar complete:' , res.data)
        setAllCats(res.data.data)
    })

    useEffect(() => {
        GetStoreCatsSidebar()
        GetStoreBrandSidebar()
    },[])

    return (
        <>
            <div className="h-16-700-i gr7">Поиск по товарам</div>
                <input type="text" placeholder="Поиск по товарам..." className="base-input" value={query || ''} onChange={(e) => setQuery(e.target.value)}/>
                <span style={{borderBottom: '2px solid #D0D5DD'}} />
                <div className="h-16-700-i gr7">Фильтрация по цене</div>
                <PriceRangeSlider rangePrice={rangePrice} setRangePrice={setRangePrice}/>
                <button className="base-btn h-14-500-i white" onClick={sortingProducts}>Фильтровать</button>
                <div className="h-16-700-i gr7">Категории товаров</div>
                <div className="col-10">
                    <ProductCatChoice res={allCats} selectedCat={selectedCat} setSelectedCat={setSelectedCat}></ProductCatChoice>
                </div>
                <span style={{borderBottom: '2px solid #D0D5DD'}} />

                <div className="h-16-700-i gr7">Бренды</div>
                <div className="col-10">
                    {allBrands?.map((el) => (
                        <button className={`product_choicecat_btn h-14-600-i gr6 ${selectedBrand === el.slug ? 'active' : ''}`} key={el.id} onClick={() => {selectedBrand === el.slug ? setSelectedBrand('') : setSelectedBrand(el.slug)}}>
                            <span>{el.name}</span>
                            <span className="ba-total">{el.product_count ? el.product_count : 0}</span>
                        </button>
                    ))}
                </div>
        </>
    )
}

export default FilterSearchBlock;