import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Pagination from "./components/Pagination";
import img_pack from "../img/img";
import { AdminStoreNewRoot, AdminStoreRedRootBase, egoda_url } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import GetStore from "../API/GetStore";

const AdminStorePage = () => {

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null)
    const [totalProducts, setTotalProducts] = useState(null)
    const [itemPodata, setItemPodata] = useState({title: 'Товар будет удален', desc: 'Вы уверены, что хотите удалить товар безвозвратно?'});
    const [curSlug, setCurSlug] = useState('');

    const [productsAll, setProductsAll] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    
    // checbox click
    function checkClick(e) {
        if (e.target.classList.contains('active')) {
            e.target.classList.remove('active')
        } else {
            e.target.classList.add('active')
        }
    }
    
    // Получение всех товаров
    const [ProductsAll] = useFetching( async (page, productData) => {
        const res = await GetStore.ProductsAll(page, productData)
        console.log('ProductsAll complete:' , res.data)
        setProductsAll(res.data?.result)
        setTotalPages(res.data?.total_pages)
        setTotalProducts(res.data?.total)
    })
    
    useEffect(() => {
        ProductsAll(currentPage,'')
    },[currentPage])

    // Удаление поста
    function deleteProduct(slug) {
        setCurSlug(slug)
        navigate('/item_po', { state: { prevLocation: location, itemPodata: itemPodata } })
    }

    const [DeleteProduct] = useFetching( async (slug) => {
        const res = await GetStore.DeleteProduct(slug)
        console.log('DeleteProduct complete:' , res.data)
        ProductsAll(currentPage,'')
        return res;
    })

    useEffect(() => {
        if (location.state?.confirmPo) {
            // Обнуление состояния confirm в истории
            location.state.confirmPo = undefined
            window.history.replaceState({}, '')
            // Логика на confirmPo
            DeleteProduct(curSlug)
            setCurSlug('')
        }
    }, [location.state])

    return (
        <div className="admin_content_base">
            <div className="admin_content_header">
                <div className="row-10-a">
                    <div className="h-18-500-i gr8">Товары</div>
                    <span className="h-12-500-i ba-or">{totalProducts} шт</span>
                </div>
                <Link to={`${AdminStoreNewRoot}`} className="create_plus_btn">
                    <img src={img_pack.plus_icon} alt="plus_icon" />
                    <span className="h-14-600-i gr7">Создать</span>
                </Link>
            </div>

            <table className="articles_admin_table">
                <thead>
                    <tr>
                        <th>
                            <div>
                                <span className="check_box_base" onClick={checkClick}/>
                                Название
                            </div>
                        </th>
                        <th>
                            <div>
                                Статус
                                <img src={img_pack.dpd_icon} alt="dpd_icon" />
                            </div>
                        </th>
                        <th>
                            <div>Цена</div>
                        </th>
                        <th>
                            <div>Дата</div>
                        </th>
                        <th>
                            <div>Категория</div>
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {productsAll?.map((el) => (
                        <tr key={el.id}>
                            <td className="t-name">
                                <div>
                                    <span className="check_box_base" onClick={checkClick}/>
                                    {el.image_url 
                                    ?<img src={`${egoda_url}${el.image_url}`} alt="product_img" /> 
                                    :<img src={`${img_pack.product_example}`} alt="img" />}
                                    <span className="h-14-500-i gr7">{el.name}</span>
                                </div>
                            </td>
                            <td className="t-status">
                                {el.status === 'PB' ?
                                <div className="ba-pub">
                                    <div />
                                    <span className="h-12-500-i">Опубликовано</span>
                                </div>
                                : 
                                <div className="ba-dr">
                                    <div />
                                    <span className="h-12-500-i">Черновик</span>
                                </div>
                                }
                            </td>
                            <td className="t-price">
                                <div>
                                    <span className="h-14-400-i gr7">{el.price} ₽</span>
                                </div>
                            </td>
                            <td className="t-date">
                                <div>
                                    <span className="h-14-400-i gr7">{format(new Date(), "dd.MM.yyyy")}</span>
                                </div>
                            </td>
                            <td className="t-cats">
                                <div>
                                    <div key={el.id} className="h-12-500-i ba">{el.category_name}</div>

                                    {/* <button className="h-12-500-i gr5 ba_more_btn">+4</button> */}
                                </div>
                            </td>
                            <td className="t-icons">
                                <div>
                                    <button><img src={img_pack.articles_eye_icon} alt="eye_icon" /></button>
                                    <button onClick={(e) => deleteProduct(el.slug)}><img src={img_pack.articles_trash_icon} alt="trash_icon" /></button>
                                    <Link to={`${AdminStoreRedRootBase}${el.slug}`}><img src={img_pack.articles_edit_icon} alt="edit_icon" /></Link>
                                </div>
                            </td>

                        </tr>
                    ))
                    }
                </tbody>
            </table>
            {totalPages > 1 &&
            <div className="t_foot">
                <button onClick={() => setCurrentPage( ((currentPage - 1) >= 1) ? (currentPage - 1) : (currentPage) )}>
                    <span className="prev_arrow_svg"/>
                    <span className="h-14-600-i gr7">Предыдущее</span>
                </button>
                <Pagination totalPages={totalPages} pagesToShow={1} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                <button onClick={() => setCurrentPage( ((currentPage + 1) <= totalPages) ? (currentPage + 1) : (currentPage) )}>
                    <span className="h-14-600-i gr7">Следующие</span>
                    <span className="next_arrow_svg"/>
                </button>
            </div>
            }
        </div>
    )
}

export default AdminStorePage;