import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";
import CustomSelect from "./components/Custom_select";
import globalFunction, { AdminContactRedRootBase, AdminOrderRedRootBase, AdminStoreBrandsRedRoot, AdminStoreBrandsRedRootBase, AdminStoreBrandsRoot, AdminStoreCatRedRootBase, AdminStoreCatRoot, egoda_url, feedbackStatusMap } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import img_pack from "../img/img";
import GetStore from "../API/GetStore";
import EditorComponent from "./components/EditorCompoenent";
import GetFeedback from "../API/GetFeedback";
import { format } from "date-fns";
import DatePicker from "./components/DatePicker";
import GetOrder from "../API/GetOrder";

const AdminOrderRedPage = () => {

    // Первичные запросы
    
    const [status, setStatus] = useState([]);
    const [isRed, setIsRed] = useState(false);

    const [orderDetail, setOrderDetail] = useState('');
    // const [itemPodata, setItemPodata] = useState({title: 'Заявка будет удалена', desc: 'Вы уверены, что хотите удалить заявку безвозвратно?'});
    const {idParam} = useParams()
    const location = useLocation()
    const isEdit = location.pathname.includes(AdminOrderRedRootBase)
    const navigate = useNavigate()
    
    const [GetOrderDetail] = useFetching( async (id) => {
        const res = await GetOrder.GetOrderDetail(id)
        setOrderDetail(res.data.detail)
        console.log('GetOrderDetail complete:' , res.data)
        setStatus([{value: res.data.status, name: (feedbackStatusMap[res.data.status]?.text || "Неизвестный статус") }])
    })
        
    useEffect(() => {
        if(isEdit && idParam) {
            GetOrderDetail(idParam)
        }
    },[])
    
    const [UpdateOrder] = useFetching( async (data) => {
        const res = await GetOrder.UpdateOrder(data, orderDetail?.id)
        console.log('UpdateOrder complete:' , res.data)
        setIsRed(false)
        return res;
    })

    function submit(e) {
        e.preventDefault()

        const data = {
            "first_name": orderDetail.first_name || '',
            "last_name": '',
            "email": orderDetail.email || '',
            "address": orderDetail.address || '',
            "postal_code": "",
            "city": "",
            "items": orderDetail?.items.map((el) => ({
                "product": el.product.id,
                "price": el.price,
                "quantity": el.quantity
            }) ) || [],
        }

        if(isRed) {
            UpdateOrder(data)
        }
        
    }

    useEffect(() => {
        console.log(orderDetail)
    },[orderDetail])


    return (
        <div className="admin_content_base" >
            <form onSubmit={submit} className="new_category_form">
                <div className="row-20-js">
                    <span className="h-18-500-i gr7">Заказ №{orderDetail?.id}</span>
                    {!isRed ?
                    <button key={1} className="admin_save_btn" type='button' onClick={() => !isRed && setIsRed(true)}>
                        <img src={img_pack.save_icon} alt="save_icon" />
                        <span className="h-12-600-i white">{'Редактировать'}</span>
                    </button>
                    : 
                    <button className="admin_save_btn 1" type='submit'>
                        <img src={img_pack.save_icon} alt="save_icon" />
                        <span className="h-12-600-i white">{'Сохранить'}</span>
                    </button>
                    }
                </div>
                <div style={{display: 'flex', gap: '50px'}}>
                    <div className="col-20" style={{flex: '1'}}>
                        <div className="h-16-700-i gr7">Детали заказа:</div>
                        <div className="col">
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Заказ №</div>
                                <div className="h-14-400-i">{orderDetail.id}</div>
                            </div>
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Статус</div>
                                <div className="h-14-400-i">??</div>
                            </div>
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Чек об оплате</div>
                                <div className="h-14-400-i">??</div>
                            </div>
                        </div>
                        <div className="h-16-700-i gr7">Информация о клиенте</div>
                        <div className="col">
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Имя</div>
                                <div className="h-14-400-i">{isRed ? <input name="name" type="text" className="base-input" value={orderDetail?.first_name || ''} onChange={(e) => setOrderDetail({...orderDetail, first_name: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{orderDetail?.first_name}</div>}</div>
                            </div>
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Телефон</div>
                                <div className="h-14-400-i">{isRed ? <input name="tel" type="text" className="base-input" value={orderDetail?.tel || ''} onChange={(e) => setOrderDetail({...orderDetail, tel: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{orderDetail?.tel}</div>}</div>
                            </div>
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Email</div>
                                <div className="h-14-400-i">{isRed ? <input name="email" type="text" className="base-input" value={orderDetail?.email || ''} onChange={(e) => setOrderDetail({...orderDetail, email: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{orderDetail?.email}</div>}</div>
                            </div>
                            <div style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i" style={{alignSelf: 'center'}}>Адрес доставки</div>
                                <div className="h-14-400-i">{isRed ? <input name="address" type="text" className="base-input" value={orderDetail?.address || ''} onChange={(e) => setOrderDetail({...orderDetail, address: e.target.value})} /> : <div style={{textAlign: 'right'}} className="h-14-400-i">{orderDetail?.address}</div>}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-20" style={{flex: '1'}}>
                        <div className="h-16-700-i gr7">Содержимое заказа</div>
                        <div className="col">
                            <div style={{padding: '20px 10px', display: 'flex', gap: '20px', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #D0D5DD'}}>
                                <div className="h-14-400-i">Товар</div>
                                <div className="h-14-400-i">Сумма</div>
                            </div>
                            {orderDetail?.items?.map((el) => (
                                <div key={el.product.id} style={{padding: '20px 10px', gap: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #D0D5DD', gap: '20px'}}>
                                    {el.product.image_url ?
                                        <img style={{height: '50px', width: '50px', borderRadius: '10px'}} src={`${egoda_url}${el.product.image_url}`} alt="img" />
                                        : <img style={{height: '50px', width: '50px'}} src={`${img_pack.product_example}`} alt="img" />
                                    }
                                    <div className="h-14-400-i gr8" style={{width: '100%'}}>{el.product.name} <span className="h-14-700-i">({el.quantity} шт)</span></div>
                                    <div className="h-14-400-i" style={{minWidth: 'max-content'}}>{globalFunction.withSpaces(globalFunction.split(el.price)[0])} ₽</div>
                                </div>
                            ))}
                            <div style={{padding: '20px 0', gap: '20px', display: 'flex', justifyContent: 'space-between'}}>
                                <div className="h-14-700-i" style={{alignSelf: 'center'}}>ИТОГО:</div>
                                <div className="h-30-400-i">{orderDetail?.total_price_with_discount && globalFunction.withSpaces(globalFunction.split(orderDetail?.total_price_with_discount)[0])} ₽</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AdminOrderRedPage;