import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import img_pack from "../img/img";
import { useFetching } from "../hooks/UseFetching";
import Pagination from "./components/Pagination";
import { AdminStoreCatNewRoot, AdminStoreCatRedRootBase, AdminStoreCouponsNewRoot, AdminStoreCouponsRedRootBase } from "../utils/consts";
import GetStore from "../API/GetStore";
import { format } from "date-fns";

const AdminStoreCouponsPage = () => {

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null)
    const [totalPosts, setTotalPosts] = useState(null)
    const [curId, setCurId] = useState('');
    const [itemPodata, setItemPodata] = useState({title: 'Купон будет удален', desc: 'Вы уверены, что хотите удалить купон безвозвратно?'});

    const [couponsAll, setCouponsAll] = useState([])
    const location = useLocation()
    const navigate = useNavigate()

    function checkClick(e) {
        if (e.target.classList.contains('active')) {
            e.target.classList.remove('active')
        } else {
            e.target.classList.add('active')
        }
    }

    const [GetStoreCouponsPage, isLoading] = useFetching( async (page) => {
        const res = await GetStore.GetStoreCouponsPage(page)
        setCouponsAll(res.data.data)
        setTotalPages(res.data?.total_pages)
        setTotalPosts(res.data?.total)
        console.log('GetStoreCouponsPage complete:' , res.data)
    })

    useEffect(() => {
        GetStoreCouponsPage(currentPage)
    },[currentPage])

    // Удаление категории
    function deleteCoupon(id) {
        setCurId(id)
        navigate('/item_po', { state: { prevLocation: location, itemPodata: itemPodata } })
    }

    const [DeleteStoreCoupon] = useFetching( async (id) => {
        const res = await GetStore.DeleteStoreCoupon(id)
        console.log('DeleteStoreCoupon complete:' , res.data)
        GetStoreCouponsPage(1)
        return res;
    })

    useEffect(() => {
        if (location.state?.confirmPo) {
            // Обнуление состояния confirm в истории
            location.state.confirmPo = undefined
            window.history.replaceState({}, '')
            // Логика на confirmPo
            DeleteStoreCoupon(curId)
            setCurId('')
        }
    }, [location.state])

    return (
        <div className="admin_content_base">
            <div className="admin_content_header">
                <div className="row-10-a">
                    <div className="h-18-500-i gr8">Купоны</div>
                    <span className="h-12-500-i ba-or">{totalPosts} шт</span>
                </div>
                <Link to={`${AdminStoreCouponsNewRoot}`} className="create_plus_btn">
                    <img src={img_pack.plus_icon} alt="plus_icon" />
                    <span className="h-14-600-i gr7">Создать</span>
                </Link>
            </div>

            <table className="articles_admin_table">
                <thead>
                    <tr>
                        <th>
                            <div>
                                <span className="check_box_base" onClick={checkClick}/>
                                Код 
                            </div>
                        </th>
                        <th>
                            <div>Тип купона</div>
                        </th>
                        <th>
                            <div>Величина</div>
                        </th>
                        <th>
                            <div>Срок действия</div>
                        </th>
                        <th>
                            <div>Использовано / Лимит</div>
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {couponsAll?.map((el) => (
                        <tr key={el.id}>
                            <td className="tcoupons-name">
                                <div>
                                    <span className="check_box_base" onClick={checkClick}/>
                                    <span className="h-14-500-i gr7">{el.code}</span>
                                </div>
                            </td>
                            <td className="tcoupons-type">
                                <div>
                                    <span className="h-14-400-i gr7">{el.type_discount === 'SUM' ? 'Сумма скидки' : 'Процент скидки'}</span>
                                </div>
                            </td>
                            <td className="tcoupons-value">
                                <div>
                                    <span className="h-14-400-i gr7">{el.coupon_value}</span>
                                </div>
                            </td>
                            <td className="tcoupons-date">
                                <div>
                                    <span className="h-14-400-i gr7">{el.coupon_expiration_date && format(new Date(el.coupon_expiration_date), 'dd-MM-yyyy')}</span>
                                </div>
                            </td>
                            <td className="tcoupons-limit">
                                <span className="h-12-500-i gr7">{el.times_used} / {el.coupon_limit} </span>
                            </td>
                            <td className="t-icons">
                                <div>
                                    <button onClick={() => deleteCoupon(el.id)}><img src={img_pack.articles_trash_icon} alt="trash_icon" /></button>
                                    <Link to={`${AdminStoreCouponsRedRootBase}${el.id}`}><img src={img_pack.articles_edit_icon} alt="edit_icon" /></Link>
                                </div>
                            </td>

                        </tr>
                    ))
                    }
                </tbody>
            </table>
            {totalPages > 1 &&
            <div className="t_foot">
                <button onClick={() => setCurrentPage( ((currentPage - 1) >= 1) ? (currentPage - 1) : (currentPage) )}>
                    <span className="prev_arrow_svg"/>
                    <span className="h-14-600-i gr7">Предыдущее</span>
                </button>
                <Pagination totalPages={totalPages} pagesToShow={1} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                <button onClick={() => setCurrentPage( ((currentPage + 1) <= totalPages) ? (currentPage + 1) : (currentPage) )}>
                    <span className="h-14-600-i gr7">Следующие</span>
                    <span className="next_arrow_svg"/>
                </button>
            </div>
            }
        </div>
    )
}

export default AdminStoreCouponsPage;