import React, { useContext, useEffect, useState } from "react"
import { Context } from "../App";
import { products } from "../utils/consts";
import { useNavigate } from "react-router";
import { useFetching } from "../hooks/UseFetching";
import StoreLsidebar from "./components/StoreLsidebar";
import ProductExample from "./components/Product_example";
import GetStore from "../API/GetStore";
import CustomSelect from "./components/Custom_select";
import Pagination from "./components/Pagination";
import ProductExample_line from "./components/ProductExample_line";
import img_pack from "../img/img";
import SotreSearchBlock from "./components/StoreSearchBlock";
import FilterSearchBlock from "./components/FilterSearchBlock";

const StorePage = () => {

    const {windowWidth} = useContext(Context)
    const navigate = useNavigate()
    const [productsAll, setProductsAll] = useState([])
    const [activeSort, setActiveSort] = useState([])
    const [rangePrice, setRangePrice] = useState(["", ""]);
    const [query, setQuery] = useState('');

    const [mobileMenu1, setMobileMenu1] = useState(false);
    const [mobileMenu2, setMobileMenu2] = useState(false);

    const [viewSelect, setViewSelect] = useState(false);

    const [selectedCat, setSelectedCat] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null)
    const [totalProducts, setTotalProducts] = useState(null)

    // Получение всех товаров
    const [UsersProductsAll] = useFetching( async (productData, page) => {
        const res = await GetStore.UsersProductsAll(productData, page)
        console.log('UsersProductsAll complete:' , res.data)
        setProductsAll(res.data?.result)
        setTotalPages(res.data?.total_pages)
        setTotalProducts(res.data?.total)
    })
    
    useEffect(() => {
        sortingProducts()
    },[currentPage, activeSort, selectedCat, selectedBrand])
    
    // Сортировка по цене 
    function sortingProducts() {
        const formdata = new FormData();

        formdata.append("query", query || ''); //поиск по тексту
        formdata.append("price_from", rangePrice[0] || ''); //цена от
        formdata.append("price_to", rangePrice[1] || ''); //цена до
        formdata.append("category", selectedCat || ''); //категория (если есть потомки, то отображаются товары потомков в т.ч.)
        formdata.append("brand", selectedBrand || ''); //slug бренда
        formdata.append("sort_by", activeSort[0]?.value || ''); //created_desc / created_asc / price_desc / price_asc
        formdata.append("item_quantity", 12); //кол-во итемов для вывода (дефолт 12)

        UsersProductsAll(formdata, currentPage)
    }

    useEffect(() => {
        if(windowWidth<= 1000) {
            setViewSelect(true)
        }
    },[windowWidth])

    return (
        <>
        {windowWidth <= 1000 &&
        <div className={`overlay ${mobileMenu1 ? 'open' : ''}`} onClick={() => setMobileMenu1(false)}>
            <div className={`mobile_menu ${mobileMenu1 ? 'open' : ''}`} onClick={e => e.stopPropagation()} style={{maxWidth: '300px', width: '100%'}}>
                <div className="mobile_menu_content">
                    <button className="close-btn" onClick={() => setMobileMenu1(false)} style={{position: 'absolute', right: '16px'}}><img src={img_pack.close_icon} alt="close_icon"/></button>
                    <div style={{padding: '10px 16px'}} className="col-20">
                        <SotreSearchBlock />
                    </div>
                </div>
            </div>
        </div>
        } 
         {windowWidth <= 1000 &&
        <div className={`overlay ${mobileMenu2 ? 'open' : ''}`} onClick={() => setMobileMenu2(false)}>
            <div className={`mobile_menu ${mobileMenu2 ? 'open' : ''}`} onClick={e => e.stopPropagation()} style={{maxWidth: '300px', width: '100%'}}>
                <div className="mobile_menu_content">
                    <button className="close-btn" onClick={() => setMobileMenu2(false)} style={{position: 'absolute', right: '16px'}}><img src={img_pack.close_icon} alt="close_icon"/></button>
                    <div style={{padding: '10px 16px'}} className="col-20">
                        <FilterSearchBlock rangePrice={rangePrice} setRangePrice={setRangePrice} sortingProducts={sortingProducts} query={query} setQuery={setQuery} selectedCat={selectedCat} setSelectedCat={setSelectedCat} selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand}/>
                    </div>
                </div>
            </div>
        </div>
        } 
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1000 &&
                        <StoreLsidebar rangePrice={rangePrice} setRangePrice={setRangePrice} sortingProducts={sortingProducts} query={query} setQuery={setQuery} selectedCat={selectedCat} setSelectedCat={setSelectedCat} selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand}/>
                    }
                    <div className="block-wls-content">
                        <div className="products-title storePage">
                            {windowWidth > 1000 ?
                            <div className="h-12-400-i gr7">Показано {productsAll?.length} товаров из {totalProducts}</div>
                            :
                            <div className="row-15-a">
                                <button className="row-10-a base-btn-wh" onClick={() => setMobileMenu1(true)}>
                                    <div className="h-14-400-j">Подбор</div>
                                </button>
                                <button className="row-10-a base-btn-wh" onClick={() => setMobileMenu2(true)}>
                                    <div className="h-14-400-j">Фильтр</div>
                                </button>
                            </div>
                            }
                            <div className="row-20-a">
                                <div style={{width: '150px'}}>
                                    <CustomSelect allOption={[{id:1, slug: 'price_asc', name: 'По возрастанию цены'},{id:2, slug: 'price_desc', name: 'По убыванию цены'},{id:3, slug: 'created_desc', name: 'По новизне'}]} ActiveItems={activeSort} setActiveItems={(e) => setActiveSort(e)} single={true} enclosure={false} placeholder={'Поиск по товарам...'}/>
                                </div>
                                {windowWidth > 1000 &&
                                <div className="dual-btn">
                                    <button className={`h-14-400-j ${!viewSelect ? 'active white' : ''}`} onClick={() => setViewSelect(false)}><img_pack.menu_component_icon className={`dual-btn-img ${!viewSelect ? 'active' : ''}`} /></button>
                                    <button className={`h-14-400-j ${viewSelect ? 'active white' : ''}`} onClick={() => setViewSelect(true)}><img_pack.grid_component_icon className={`dual-btn-img ${viewSelect ? 'active' : ''}`} /></button>
                                </div>}
                            </div>
                        </div>

                        <div className={`${viewSelect ? 'products-block' : 'products-block_line'}`}>
                            {productsAll?.map((el) => (
                                viewSelect ?
                                <ProductExample key={el.id} res={el} className={'productExample-storeMob'}></ProductExample>
                                : <ProductExample_line key={el.id} res={el}></ProductExample_line>
                            ))}
                        </div>

                        {totalPages > 1 &&
                        <div className="t_foot">
                            <button onClick={() => setCurrentPage( ((currentPage - 1) >= 1) ? (currentPage - 1) : (currentPage) )}>
                                <span className="prev_arrow_svg"/>
                                <span className="h-14-600-i gr7">Предыдущее</span>
                            </button>
                            <Pagination totalPages={totalPages} pagesToShow={1} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                            <button onClick={() => setCurrentPage( ((currentPage + 1) <= totalPages) ? (currentPage + 1) : (currentPage) )}>
                                <span className="h-14-600-i gr7">Следующие</span>
                                <span className="next_arrow_svg"/>
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default StorePage;