import React, { useContext, useEffect, useState } from "react"
import CustomSelect from "./Custom_select";
import { useLocation } from "react-router";

const SotreSearchBlock = () => {

    const location = useLocation()
    let searchInitialData = location.state?.searchInitialData

    const [selected1, setSelected1] = useState(false)
    const [selected2, setSelected2] = useState(false)
    const [selected3, setSelected3] = useState(false)
    
    const [dataByCarTires, setDataByCarTires] = useState({
        stamp: [],
        model: [],
        year: [],
        engine: []
    })
    const [dataByParamTires, setDataByParamTires] = useState({
        width: [],
        profile: [],
        diameter: [],
        axis: [],
        seasonality: [],
        type: [],
        manufacturer: [],
    })

    const [dataByCarDiscs, setDataByCarDiscs] = useState({
        stamp: [],
        model: [],
        year: [],
        engine: []
    })
    const [dataByParamDiscs, setDataByParamDiscs] = useState({
        width: [],
        diameter: [],
        pcd: [],
        et: [],
        hub: [],
        manufacturer: [],
    })

    // useEffect(() => {
    //     // setSelected2(false)
    //     // setSelected3(false)
    // },[selected1])

    // useEffect(() => {
    //     console.log(dataByCarTires,dataByParamTires)
    // },[dataByCarTires,dataByParamTires])

    function submit() {
        if(!selected1) {
            if(selected2) {
                console.log('Подбор шин, По автомобилю')
            } else {
                if(selected3) {
                    console.log('Подбор шин, По параметрам, Грузовые')
                } else {
                    console.log('Подбор шин, По параметрам, Легковые')
                }
            }
        } else {
            if(selected2) {
                console.log('Подбор дисков, По автомобилю')
            } else {
                console.log('Подбор дисков, По параметрам')
            }
        }
    }

    useEffect(() => {
        console.log('searchInitialData',searchInitialData)
        if(searchInitialData?.selected1 !== undefined) {
            setSelected1(searchInitialData.selected1)
        }
        if(searchInitialData?.selected2 !== undefined) {
            setSelected2(searchInitialData.selected2)
        }
        if(searchInitialData?.selected3 !== undefined) {
            setSelected3(searchInitialData.selected3)
        }
        if(searchInitialData?.data) {
            if(!searchInitialData?.selected1) {
                if(searchInitialData?.selected2) {
                    setDataByCarTires(searchInitialData?.data)
                } else {
                    if(searchInitialData?.selected3) {
                        setDataByParamTires(searchInitialData?.data)
                    } else {
                        setDataByParamTires(searchInitialData?.data)
                    }
                }
            } else {
                if(searchInitialData?.selected2) {
                    setDataByCarDiscs(searchInitialData?.data)
                } else {
                    setDataByParamDiscs(searchInitialData?.data)
                }
            }
        }
        // Обнуление состояния в истории
        searchInitialData = undefined
        window.history.replaceState({}, '')
    },[searchInitialData])


    return (
        <>
            <div className="h-16-700-i gr7">Подбор шин и дисков</div>
            <div className="dual-btn">
                <button className={`h-14-400-j ${!selected1 ? 'active white' : ''}`} onClick={() => setSelected1(false)}>Шины</button>
                <button className={`h-14-400-j ${selected1 ? 'active white' : ''}`} onClick={() => setSelected1(true)}>Диски</button>
            </div>
            <div className="dual-btn">
                <button className={`h-14-400-j ${!selected2 ? 'active white' : ''}`} onClick={() => setSelected2(false)}>По параметрам</button>
                <button className={`h-14-400-j ${selected2 ? 'active white' : ''}`} onClick={() => setSelected2(true)}>По автомобилю</button>
            </div>
            {(!selected1 && !selected2) &&
            <div className="dual-btn">
                <button className={`h-14-400-j ${!selected3 ? 'active white' : ''}`} onClick={() => setSelected3(false)}>Легковые</button>
                <button className={`h-14-400-j ${selected3 ? 'active white' : ''}`} onClick={() => setSelected3(true)}>Грузовые</button>
            </div>}
          
            {!selected1 ?
                (!selected2 ?
                <>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.width || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, width: e})} single={true} enclosure={false} placeholder={'Ширина, мм'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.profile || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, profile: e})} single={true} enclosure={false} placeholder={'Профиль'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.diameter || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, diameter: e})} single={true} enclosure={false} placeholder={'Диаметр'}/>
                    {selected3 &&
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.axis || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, axis: e})} single={true} enclosure={false} placeholder={'Ось'}/>
                    }
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.seasonality || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, seasonality: e})} single={true} enclosure={false} placeholder={'Сезонность'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.type || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, type: e})} single={true} enclosure={false} placeholder={'Тип шин'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.manufacturer || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, manufacturer: e})} single={true} enclosure={false} placeholder={'Производитель'}/>
                </>
                :
                <>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarTires?.stamp || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, stamp: e})} single={true} enclosure={false} placeholder={'Марка'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarTires?.model || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, model: e})} single={true} enclosure={false} placeholder={'Модель'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarTires?.year || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, year: e})} single={true} enclosure={false} placeholder={'Год выпуска'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarTires?.engine || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, engine: e})} single={true} enclosure={false} placeholder={'Двигатель'}/>
                </>)
            :
                (!selected2 ?
                <>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamDiscs?.width || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, width: e})} single={true} enclosure={false} placeholder={'Ширина, дюйм'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamDiscs?.diameter || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, diameter: e})} single={true} enclosure={false} placeholder={'Диаметр, дюйм'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamDiscs?.pcd || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, pcd: e})} single={true} enclosure={false} placeholder={'PCD (крепёж)'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamDiscs?.et || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, et: e})} single={true} enclosure={false} placeholder={'ET (вылет)'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamDiscs?.hub || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, hub: e})} single={true} enclosure={false} placeholder={'Ступица'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamDiscs?.manufacturer || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, manufacturer: e})} single={true} enclosure={false} placeholder={'Производитель'}/>
                </>
                :
                <>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarDiscs?.stamp || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, stamp: e})} single={true} enclosure={false} placeholder={'Марка'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarDiscs?.model || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, model: e})} single={true} enclosure={false} placeholder={'Модель'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarDiscs?.year || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, year: e})} single={true} enclosure={false} placeholder={'Год выпуска'}/>
                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarDiscs?.engine || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, engine: e})} single={true} enclosure={false} placeholder={'Двигатель'}/>
                </>)

            }
            <button className="base-btn white h-14-400-j" onClick={submit}>Найти</button>
        </>
    )
}

export default SotreSearchBlock;