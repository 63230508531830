import React, { useEffect, useRef, useState } from "react"

const Product_tabs = ({productTabs}) => {

    const [selectedBtn, setSelectedBtn] = useState('')

    useEffect(() => {
        if(productTabs) {
            setSelectedBtn(productTabs[0]?.id)
        }
    },[productTabs])


    function menuBtnClick(e) {
        const numb = e.currentTarget.getAttribute('numb')
        if(selectedBtn !== numb) {
            setSelectedBtn(numb)
        }
    }

    return (
        <>
        {productTabs?.length > 0 &&
        <div className="product_tabs">
            {productTabs?.map((el) => (
                <button className={`h-14-600-i ${selectedBtn === el.id ? 'active' : ''}`} key={el.id} onClick={() => setSelectedBtn(el.id)}>{el.name}</button>
            ))}
        </div>}
        {(selectedBtn !== '' && productTabs?.length > 0) &&
            <div>
                {productTabs?.find(obj => obj.id === selectedBtn).value?.blocks?.map((el) => (
                    el.type === 'header' ? React.createElement(`h${el.data.level}`, { key: el.id, id: el.id, className: "h-21-600-sf", dangerouslySetInnerHTML: { __html: el.data.text } })
                    : el.type === 'paragraph' ? React.createElement(`p`, { key: el.id, className: "h-16-400-sf c-6", dangerouslySetInnerHTML: { __html: el.data.text } })
                    : <span key={el.id} className="h-16-400-sf c-6" dangerouslySetInnerHTML= {{ __html: (el.data.text || el.data.code) }}></span>
                ))}
            </div>
        }
        </>
    )
}

export default Product_tabs;