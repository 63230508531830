import React, { useContext, useEffect, useState } from "react"
import CustomChoiceDpd from "./CustomChoiceDpd";

const ProductCatChoice = ({res, selectedCat, setSelectedCat}) => {

    function CatChoiceClick(slug) {
        if(selectedCat === slug) {
            setSelectedCat('')
        } else {
            setSelectedCat(slug)
        }
    }

    return (
        <>
        {res?.map((el) => (
            !el.parent &&
                (el.children.length > 0 ?
                <CustomChoiceDpd key={el.id} el={el} q={el.product_total} selectedCat={selectedCat} setSelectedCat={setSelectedCat}>
                    {el.children?.map((child) => (
                        child.children.length > 0 ?
                        <div key={child.id} style={{display: 'flex', flexDirection: 'column'}}>
                            <button className={`product_choicecat_btn le-1 h-14-600-i gr6 ${selectedCat === child.slug ? 'active' : ''}`} onClick={() => CatChoiceClick(child.slug)}>
                                <span>{child.name}</span>
                                <span className="ba-total">{child.product_total}</span>
                            </button>
                            {child.children?.map((child1) => (
                                <button className={`product_choicecat_btn le-2 h-14-600-i gr6 ${selectedCat === child1.slug ? 'active' : ''}`} key={child1.id} onClick={() => CatChoiceClick(child1.slug)}>
                                    <span>{child1.name}</span>
                                    <span className="ba-total">{child1.product_total}</span>
                                </button>
                            ))}
                        </div>
                        :
                        <button className={`product_choicecat_btn le-1 h-14-600-i gr6 ${selectedCat === child.slug ? 'active' : ''}`} key={child.id} onClick={() => CatChoiceClick(child.slug)}>
                            <span>{child.name}</span>
                            <span className="ba-total">{child.product_total}</span>
                        </button>
                    ))}
                </CustomChoiceDpd>
                :
                <button className={`product_choicecat_btn h-14-600-i gr6 ${selectedCat === el.slug ? 'active' : ''}`} key={el.id} onClick={() => CatChoiceClick(el.slug)}>
                    <span>{el.name}</span>
                    <span className="ba-total">{el.product_total}</span>
                </button>)
        ))}
        </>
    )
}

export default ProductCatChoice;