import React, { useContext, useEffect, useState } from "react"
import ToggleBtn from "./toggleBtn";
import CustomSelect from "./Custom_select";
import img_pack from "../../img/img";
import { useNavigate } from "react-router";
import { StorePageRoot } from "../../utils/consts";

const SearchBlock = () => {

    const navigate = useNavigate()

    const [selectedBtn, setSelectedBtn] = useState('1')
    const [toggle1, setToggle1] = useState(false)
    const [toggle2, setToggle2] = useState(false)
    
    const [dataByCarTires, setDataByCarTires] = useState({
        stamp: [],
        model: [],
        year: [],
        engine: []
    })
    const [dataByParamTires, setDataByParamTires] = useState({
        width: [],
        profile: [],
        diameter: [],
        axis: [],
        seasonality: [],
        type: [],
        manufacturer: [],
    })

    const [dataByCarDiscs, setDataByCarDiscs] = useState({
        stamp: [],
        model: [],
        year: [],
        engine: []
    })
    const [dataByParamDiscs, setDataByParamDiscs] = useState({
        width: [],
        diameter: [],
        pcd: [],
        et: [],
        hub: [],
        manufacturer: [],
    })

    useEffect(() => {
        setToggle1(false)
        setToggle2(false)
    },[selectedBtn])

    useEffect(() => {
        console.log(dataByCarTires,dataByParamTires)
    },[dataByCarTires,dataByParamTires])

    function submit() {
        if(selectedBtn === '1') {
            if(toggle1) {
                navigate(`${StorePageRoot}`, { state: { searchInitialData: {selected1: false, selected2: true, data: dataByCarTires} } })
                console.log('Подбор шин, По автомобилю')
            } else {
                if(toggle2) {
                    navigate(`${StorePageRoot}`, { state: { searchInitialData: {selected1: false, selected2: false, selected3: true, data: dataByParamTires} } })
                    console.log('Подбор шин, По параметрам, Грузовые')
                } else {
                    navigate(`${StorePageRoot}`, { state: { searchInitialData: {selected1: false, selected2: false, selected3: false, data: dataByParamTires} } })
                    console.log('Подбор шин, По параметрам, Легковые')
                }
            }
        } else {
            if(toggle1) {
                navigate(`${StorePageRoot}`, { state: { searchInitialData: {selected1: true, selected2: true, data: dataByCarDiscs} } })
                console.log('Подбор дисков, По автомобилю')
            } else {
                navigate(`${StorePageRoot}`, { state: { searchInitialData: {selected1: true, selected2: false, data: dataByParamDiscs} } })
                console.log('Подбор дисков, По параметрам')
            }
        }
    }


    return (
        <>
            <div className="search-block">
                <div className="search-block-btns h-18-400-j">
                    <button className={`${selectedBtn === '1' ? 'active' : ''}`} onClick={() => setSelectedBtn('1')}>Подбор шин</button>
                    <button className={`${selectedBtn === '2' ? 'active' : ''}`} onClick={() => setSelectedBtn('2')}>Подбор дисков</button>
                </div>
                <div className="search-block-main">
                    {selectedBtn === '1' &&
                    <>
                        <div className="col-10">
                            <div className="row-20-a h-14-400-i">
                                <span style={toggle1 ? {color: '#98A2B3'} : {color: '#000000'}}>По параметрам</span>
                                <ToggleBtn toggleActive={toggle1} onChange={(e) => setToggle1(e)} />
                                <span style={!toggle1 ? {color: '#98A2B3'} : {color: '#000000'}}>По автомобилю</span>
                            </div>
                            {!toggle1 &&
                            <div className="row-20-a h-14-400-i">
                                <span style={toggle2 ? {color: '#98A2B3'} : {color: '#000000'}}>Легковые</span>
                                <ToggleBtn toggleActive={toggle2} onChange={(e) => setToggle2(e)} />
                                <span style={!toggle2 ? {color: '#98A2B3'} : {color: '#000000'}}>Грузовые</span>
                            </div>
                            }
                        </div>
                        {toggle1 ?
                        <div className="col-15">
                            <div className="row-10">
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarTires?.stamp || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, stamp: e})} single={true} enclosure={false} placeholder={'Марка'}/>
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarTires?.model || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, model: e})} single={true} enclosure={false} placeholder={'Модель'}/>
                            </div>
                            <div className="row-10">
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarTires?.year || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, year: e})} single={true} enclosure={false} placeholder={'Год выпуска'}/>
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarTires?.engine || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, engine: e})} single={true} enclosure={false} placeholder={'Двигатель'}/>
                            </div>
                        </div>
                        : 
                        <div className="col-15">
                            <div className="row-10">
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.width || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, width: e})} single={true} enclosure={false} placeholder={'Ширина, мм'}/>
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.profile || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, profile: e})} single={true} enclosure={false} placeholder={'Профиль'}/>
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.diameter || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, diameter: e})} single={true} enclosure={false} placeholder={'Диаметр'}/>
                            </div>
                            <div className="row-10">
                                {toggle2 &&
                                    <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.axis || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, axis: e})} single={true} enclosure={false} placeholder={'Ось'}/>
                                }
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.seasonality || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, seasonality: e})} single={true} enclosure={false} placeholder={'Сезонность'}/>
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.type || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, type: e})} single={true} enclosure={false} placeholder={'Тип шин'}/>
                            </div>
                            <div className="row-10">
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamTires?.manufacturer || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, manufacturer: e})} single={true} enclosure={false} placeholder={'Производитель'}/>
                            </div>
                        </div>
                        }
                    </>
                    }
                    {selectedBtn === '2' &&
                    <>
                        <div className="col-10">
                            <div className="row-20-a h-14-400-i">
                                <span style={toggle1 ? {color: '#98A2B3'} : {color: '#000000'}}>По параметрам</span>
                                <ToggleBtn toggleActive={toggle1} onChange={(e) => setToggle1(e)} />
                                <span style={!toggle1 ? {color: '#98A2B3'} : {color: '#000000'}}>По автомобилю</span>
                            </div>
                        </div>
                        {toggle1 ?
                        <div className="col-15">
                            <div className="row-10">
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarDiscs?.stamp || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, stamp: e})} single={true} enclosure={false} placeholder={'Марка'}/>
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarDiscs?.model || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, model: e})} single={true} enclosure={false} placeholder={'Модель'}/>
                            </div>
                            <div className="row-10">
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarDiscs?.year || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, year: e})} single={true} enclosure={false} placeholder={'Год выпуска'}/>
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByCarDiscs?.engine || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, engine: e})} single={true} enclosure={false} placeholder={'Двигатель'}/>
                            </div>
                        </div>
                        : 
                        <div className="col-15">
                            <div className="row-10">
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamDiscs?.width || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, width: e})} single={true} enclosure={false} placeholder={'Ширина, дюйм'}/>
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamDiscs?.diameter || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, diameter: e})} single={true} enclosure={false} placeholder={'Диаметр, дюйм'}/>
                            </div>
                            <div className="row-10">
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamDiscs?.pcd || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, pcd: e})} single={true} enclosure={false} placeholder={'PCD (крепёж)'}/>
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamDiscs?.et || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, et: e})} single={true} enclosure={false} placeholder={'ET (вылет)'}/>
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamDiscs?.hub || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, hub: e})} single={true} enclosure={false} placeholder={'Ступица'}/>
                            </div>
                            <div className="row-10">
                                <CustomSelect allOption={[{id:1,slug:1,name:1},{id:2,slug:2,name:2}]} ActiveItems={dataByParamDiscs?.manufacturer || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, manufacturer: e})} single={true} enclosure={false} placeholder={'Производитель'}/>
                            </div>
                        </div>
                        }
                    </>
                    }
                    <button className="base-btn white h-14-400-j" onClick={submit}>Найти</button>
                    <div className="row-40">
                        <button className="row-10-a h-14-400-i gr5">
                            <img src={img_pack.input_disc_icon} alt="ic" />
                            <span>Где смотреть параметры?</span>
                        </button>
                        <button className="row-10-a h-14-400-i gr5">
                            <img src={img_pack.input_help_icon} alt="ic" />
                            <span>Помощь в подборе</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchBlock;