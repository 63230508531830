import { useState } from "react";
import { useFetching } from "./UseFetching";
import GetCart from "../API/GetCart";

export const useCart = () => {
    const [cart, setCart] = useState('');
    const [productsAll, setProductsAll] = useState([]);

    // Получение корзины
    const [ShowCart] = useFetching(async () => {
        const res = await GetCart.ShowCart();
        console.log('ShowCart complete123:', res.data);
        setCart(res.data.cart);
        setProductsAll(res.data.cart.items);
    });

    // Обновление корзины
    const [UpdateCart] = useFetching(async (data) => {
        const res = await GetCart.UpdateCart(data);
        console.log('UpdateCart complete:', res.data);
        ShowCart();
        return res;
    });

    // Удаление из корзины товара
    const [DeleteFromCart] = useFetching(async (data) => {
        const res = await GetCart.DeleteFromCart(data);
        console.log('DeleteFromCart complete:', res.data);
        ShowCart();
        return res;
    });

    // Изменение quantity товара корзины
    const updateQuantity = async (quantity, id) => {
        console.log(quantity, id);
        const newProductsAll = {
            cart: {
                [id]: {
                    quantity: quantity,
                },
            },
        };
        try {
            await UpdateCart(newProductsAll);
            console.log('Корзина успешно обновлена:', newProductsAll);
        } catch (error) {
            console.error('Ошибка при обновлении корзины:', error);
        }
    };

    // Добавление в корзину
    const [AddCart] = useFetching(async (data) => {
        const res = await GetCart.AddCart(data);
        console.log('AddCart complete:', res.data);
        ShowCart();
        return res;
    });

    return { cart, setCart, productsAll, setProductsAll, ShowCart, UpdateCart, DeleteFromCart, updateQuantity, AddCart };
};
