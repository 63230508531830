
import { useLocation } from 'react-router'
import { useEffect } from 'react'

export const url = 'https://api.ass74.ru'
export const Page404_Root = '/404'

export const OrderPageRootBase = '/order/'
export const OrderPageRoot = '/order/:idParam'

export const FaqPageRoot = '/faq'
export const PaymentAndDeliveryRoot = '/payment&delivery'
export const ExchangeAndRefundRoot = '/exchange&refund'
export const PrivacyPolicyRoot = '/privacypolicy'

export const CartPageRoot = '/cart'
export const CartOrderPageRoot = '/cart/order'

export const StorePageRoot = '/store'
export const ProductPageRootBase = '/store/'
export const ProductPageRoot = '/store/:catParam/:slugParam/'

export const Login_Root = '/Login'
export const Registration_Root = '/Registration'
export const Password_recovery_Root = '/PasswordRecovery'
export const Password_new_Root = '/PasswordNew'
export const Email_verify_Root = '/emailVerify'

export const AdminGeneralRoot = '/admin'

export const AdminSettingsRoot = '/admin/settings'

export const AdminOrderRoot = '/admin/order'
export const AdminOrderRedRootBase = '/admin/order/red/'
export const AdminOrderRedRoot = '/admin/order/red/:idParam/' 
export const AdminOrderSettingsRoot = '/admin/order/settings/' 

export const AdminStoreRoot = '/admin/store'
export const AdminStoreNewRoot = '/admin/store/new'
export const AdminStoreRedRootBase = '/admin/store/red/'
export const AdminStoreRedRoot = '/admin/store/red/:slugParam/' 

export const AdminStoreCatRoot = '/admin/store/category' 
export const AdminStoreCatRedRootBase = '/admin/store/category/red/' 
export const AdminStoreCatRedRoot = '/admin/store/category/red/:slugParam/' 
export const AdminStoreCatNewRoot = '/admin/store/category/new' 

// смена на роутов на order
export const AdminStoreCouponsRoot = '/admin/order/coupons' 
export const AdminStoreCouponsRedRootBase = '/admin/order/coupons/red/' 
export const AdminStoreCouponsRedRoot = '/admin/order/coupons/red/:idParam/' 
export const AdminStoreCouponsNewRoot = '/admin/order/coupons/new' 

export const AdminStoreBrandsRoot = '/admin/store/brands' 
export const AdminStoreBrandsRedRootBase = '/admin/store/brands/red/' 
export const AdminStoreBrandsRedRoot = '/admin/store/brands/red/:slugParam/' 
export const AdminStoreBrandsNewRoot = '/admin/store/brands/new' 

export const AdminContactRoot = '/admin/contact'
export const AdminContactRedRootBase = '/admin/contact/red/'
export const AdminContactRedRoot = '/admin/contact/red/:idParam/' 

export const AdminContactSettingsRoot = '/admin/contact/settings'

export const AdminMediafilesRoot = '/admin/mediafiles'
export const AdminMediafilesRedRoot = '/admin/mediafiles/red/:id'
export const AdminMediafilesRedRootBase = '/admin/mediafiles/red/'

// export const egoda_url = 'http://egoda.webbbox.ru'
export const egoda_url = 'https://api.ass74.ru'

export const feedbackStatusMap = {
    NEW: { className: "ba-new", text: "Новый" },
    WORK: { className: "ba-work", text: "В работе" },
    PROC: { className: "ba-proc", text: "Обработан" },
    CANC: { className: "ba-canc", text: "Отказ" }
};

export var cats = [
    {
        id: 0,
        title: 'Категория 1',
        desc: 'Описание категории',
    },
    {
        id: 2,
        title: 'Категория 2',
        desc: 'Описание категории',
    },
    {
        id: 3,
        title: 'Категория 3',
        desc: 'Описание категории',
    },
    {
        id: 4,
        title: 'Категория 4',
        desc: 'Описание категории',
    },
]

export default class globalFunction {
    static withSpaces(a) {
        if(a === undefined) {
            return ''
        } else {
            return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    }
    static split(a,c) {
        if (c == null) {
            c = 2;
        }
        return parseFloat(a).toFixed(c).split('.');
    }
}


export const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
        if (pathname !== '/contact_po') {
            window.scrollTo(0, 0);
        }
    }, [pathname]);
  
    return null;
};


  
